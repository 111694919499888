<template>
  <div class="container" style="align-items:center" @touchmove="handleMove" @click="handleMove">
    <img style="width:1.67rem;height:0.64rem;margin-top:0.71rem;" src="../assets/images/logo.png" alt="">
    <div class="input-bg">
      <div class="area" v-if="isPhone">+86</div>
      <div class="input1">
        <input class="input-content" id="phone" type="tel" placeholder="输入我的手机号" v-if="isPhone" v-model.trim="phone" @click.stop="">
        <input class="input-content" id="email" type="email" placeholder="输入我的邮箱" v-else v-model.trim="email" @click.stop="">
      </div>

      <a class="clear" @click="clear">
        <img class="clear" src="../assets/images/clear.png" alt=""
          v-if="(isPhone&&phone.length>0)||(!isPhone&&email.length>0)">
      </a>
    </div>

    <a class="link_btn0" style="margin-top:0.36rem;position: relative;" @click="queryAccount">{{isPhone?'发送短信验证码':'发送邮箱验证码'}}
    <input v-if="(isPhone&&phone.length>0)||(!isPhone&&email.length>0)" type="text" class="btn_input" @click="queryAccount" id="btninput"></a>

    <div class="protocol">
      <a style="height:0.2rem" @click="check">
        <img v-if="checked" style="width:0.2rem;height:0.2rem;margin-right:0.07rem" src="../assets/images/checked.png" alt="">
        <img v-else style="width:0.2rem;height:0.2rem;margin-right:0.07rem" src="../assets/images/check.png" alt="">
      </a>
      <div style="color: #74747AFF;font-size: 0.12rem;">已阅读并同意</div>
      <a class="link">
        《用户服务协议》
      </a>
      <a class="link">
        《隐私权政策》
      </a>
    </div>
    <a class="other" @click="isPhone = !isPhone">{{isPhone?'使用邮箱注册':'使用手机号注册'}}</a>

  </div>
</template>

<script>
import { get, post } from '../config/http';
import api from '../config/api';
import MonitorKeyboard from '../config/monitor_keyboard.js'
import { isAndroid } from '../config/utils';
export default {
  props: {
    msg: String
  },
  data() {
    return {
      isPhone: true,
      phone: '',
      email: '',
      checked:true
    }
  },
  mounted(){
    this.getKeyboardState();
    if (isAndroid()){
      $('.other').hide();
    }
  },
  beforeDestroy() {
    this.monitorKeyboard.onEnd()
  },
  methods: {
    getKeyboardState() {
      this.monitorKeyboard = new MonitorKeyboard();
      this.monitorKeyboard.onStart();

      // 监听虚拟键盘弹出事件
      this.monitorKeyboard.onShow(() => {
        $('.other').hide();
      })

      //监听键盘收起的事件
      this.monitorKeyboard.onHidden(() => {
        $('.other').show();
      })
    },
    handleMove(){
      if (this.isPhone){
        $('#phone').blur();
      }
      else {
        $('#email').blur();
      }
    },
    check(){
      this.checked = !this.checked
    },
    queryAccount() {
      if (this.isPhone){
        if (this.phone.length==0){
          this.$toast('请输入手机号', require('../assets/images/toast_tip.png'))
          return;
        }
      }
      else {
        if (this.email.length==0){
          this.$toast('请输入验证码', require('../assets/images/toast_tip.png'))
          return;
        }
      }
      var _this = this
      this.$toast('', '', true, -1)
      if (this.isPhone) {
        get(api.phoneCanRegister, {
          mobile: this.phone
        }).then(data => {
          if (data.success == false) {
            global.toast.show = false;
            _this.showLoginAlert(true)
            return;
          }
          _this.sendCode();
          // this.$router.push({ path: '/signup', query: { invite_code: this.code } });
        }).catch(e => {
          this.$toast(e.data.error_message, require('../assets/images/toast_tip.png'))
        })
      }
      else {
        get(api.emailCanRegister, {
          email: this.email
        }).then(data => {
          if (data.success == false) {
            global.toast.show = false;
            _this.showLoginAlert(false)
            return;
          }
          _this.sendCode();
          // this.$router.push({ path: '/signup', query: { invite_code: this.code } });
        }).catch(e => {
          this.$toast(e.data.error_message, require('../assets/images/toast_tip.png'))
        })
      }
    },

    sendCode() {
      var _this = this
      this.$toast('', '', true, -1)
      if (this.isPhone) {
        post(api.phoneSendCode, {
          mobile: this.phone
        }).then(data => {
          global.toast.show = false;
          this.$router.push({ path: '/verify', query: { invite_code: _this.$route.query.invite_code,is_phone:true,account:_this.phone } }).catch(err=>{}).catch(err=>{});
         }).catch(e => {
          this.$toast(e.data.error_message, require('../assets/images/toast_tip.png'))
        })
      } else {
        post(api.emailSendCode, {
          email: this.email
        }).then(data => {
          global.toast.show = false;
          this.$router.push({ path: '/verify', query: { invite_code: _this.$route.query.invite_code,is_phone:false,account:_this.email } }).catch(err=>{}).catch(err=>{});
         }).catch(e => {
          this.$toast(e.data.error_message, require('../assets/images/toast_tip.png'))
        })
      }
    },
    clear() {
      if (this.isPhone) {
        this.phone = ''
      }
      else {
        this.email = ''
      }
    },
    showLoginAlert(status) {
      var input = document.getElementById('btninput')
      input.blur()
      this.$modal(require('../assets/images/toast_tip.png'), `该${status ? '手机号' : '邮箱'}已注册！\n可直接前往登录`, '前往登录', '返回重新输入', () => {
        global.modal.show = false;
        this.$router.push({ path: '/login', query: { account: status ? this.phone : this.email, is_phone: status } }).catch(err=>{});
      }, () => {
        global.modal.show = false;
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.input-bg {
  margin-top: 0.36rem;
  width: 3.15rem;
  height: 0.68rem;
  border-radius: 0.16rem;
  background-color: #2C2C2EFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.16rem;
  padding-right: 0;
  box-sizing: border-box;
  overflow: hidden;

  .area {
    color: #fff;
    font-size: 0.28rem;
    font-family: 'Link';
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 0.24rem 0.24rem;
    background-image: url('../assets/images/arrow.png');
    padding-right: 0.28rem;
  }

  .clear {
    display: flex;
    width: 0.24rem;
    height: 0.24rem;
    flex-shrink: 0;
    margin-right: 0.16rem;
  }
}

.input1 {
  flex: 1;
  height: 0.64rem;
  display: flex;
  align-items: center;
}

.input-content {
  caret-color: #FF9900FF;
  font-family: 'Link';
  // font-weight: bold;
  height: 0.28rem;
  line-height: 0.28rem;
  background-color: transparent;
  font-size: 0.28rem;
  border: none;
  width: 100%;

}

input::-webkit-input-placeholder {
  color: #74747AFF;
  font-size: 0.2rem;
}

input::-moz-placeholder {
  color: #74747AFF;
  font-size: 0.2rem;
}

.protocol {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0.38rem;

  .link {
    color: #FF9900FF;
    font-size: 0.12rem;
  }
}

.other {
  position: fixed;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  text-align: center;
  color: #808080FF;
  font-size: 0.14rem;
}
.btn_input{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  opacity: 0;
}
</style>